/* General Styles */

/*
Color theme
#ff7892
#da2f47
#bb1a34
#664500
#fee7b8
#4289c1


#ffeb3b
#ff4081 
#4289c1
#fee7b8
#664500
*/



body {
  font-family: 'Rock Salt', 'Jester', cursive, sans-serif;
  background-color: #ffeb3b;
  color: #3e2723;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
}
html, body {
  overflow-x: hidden;
}

.contract-address-link {
  color: #ff4081; /* Adjust to match your color scheme */
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.contract-address-link:hover {
  color: #0056b3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header */
.header {
  background-color: #ff7892;
  padding: 10px;
  text-align: center;
  position: relative;
  z-index: 10;
}

.title {
  font-size: 3rem;
  color: #ffeb3b;
  margin: 0;
}

.tagline {
  font-size: 1.5rem;
  color: #fee7b8;
  margin: 10px 0;
}

/* Hero Section */
.hero {
  padding: 50px 0;
  color: #ff4081; /* Use the same pink color as header */
  position: relative;
  z-index: 10;
}

.hero h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-content {
  margin: 0 auto;
  max-width: 600px;
}


.follow-us {
    margin-top: 20px;
    font-size: 1.1em;
    text-align: center;
    color: #333; /* Adjust color as needed */
}

.twitter-link {
    display: inline-flex;
    align-items: center;
}

.twitter-icon {
    width: 24px;  /* Adjust size as needed */
    height: 24px;
    vertical-align: middle;
    margin-left: 8px; /* Space between text and icon */
}



.twitter-link:hover {
  color: #0d8bf2;
}


.buy-button {
  background-color: #ff4081; /* Vibrant pink */
  color: white;
  font-size: 1.2rem;
  padding: 15px 30px;
  border: none;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  margin-top: 20px;
  font-weight: bold;
  transition: all 0.3s ease; /* Smooth transition for all effects */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

.buy-button:hover {
  background-color: #ff1d63; /* Darker pink for hover */
  color: #fff;
  transform: scale(1.1); /* Slightly enlarge the button */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Increase shadow for emphasis */
  
}

.buy-button:active {
  transform: scale(1); /* Reset scale on click */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Restore original shadow */
}

.background-music {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.play-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.play-button img {
  width: 96px;
  height: 96px;
}


/* Info Section */
.info-section {
  background-color: #ffeb3b;
  padding: 10px 20px;
  color: #3e2723;
  position: relative;
}

.info-section h3 {
  font-size: 2rem;
  font-weight: bold;
}

.info-section p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.info-section .buy-button {
  font-size: 1.2rem;
}

/*SEND SECTION*/
.send-section {
  margin-top: 40px;
  text-align: center;
  background-color: #ffeb3b; /* Light off-yellow background */
  padding: 20px;
  color: #ff4081;
  display: flex;
  flex-direction: column; /* Align children (image and button) in a column */
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
}

.send-section h3 {
  font-size: 2rem;
  font-weight: bold;
}

.send-section p {
  
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Clown Image Styling */
.clown-image {
  width: 60%; /* Adjust the image size to be smaller */
  max-width: 300px; /* Limit the max size */
  height: auto;
  margin-top: 20px; /* Space between button and image */
  border-radius: 10px; /* Rounded corners for the image */
}

.clown-image-hero {
  width: 60%; /* Adjust the image size to be smaller */
  max-width: 300px; /* Limit the max size */
  height: auto;
  margin-top: -30px; /* Space between button and image */
  border-radius: 10px; /* Rounded corners for the image */
}




/* Footer */
footer {
  background-color: #ff7892; /* Clownish blue, can adjust slightly for the exact look */
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #ffffff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Floating Action Button (FAB) */
.fab {
  position: fixed;
  bottom: 40px;
  right: 20px;
  width: 120px;
  height: 120px;
  background-color: #ff4081; /* Background color for button */
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.fab:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}

.fab-image {
  width: 80%; /* Resize image to fit within the button */
  height: 80%;
  border-radius: 50%; /* Ensures the image is circular */
}


/* Random Floating Clown Emojis */
.emoji {
  position: absolute;
  font-size: 3rem;
  animation: infinite;
  z-index: 1; /* Ensure emojis stay in the background */
  opacity: 1; /* Ensure emojis are visible immediately */
  transition: animation-duration 0.2s ease-in-out;
  pointer-events: none;
}
/* Random Animation Paths - Updated for full-screen coverage */

/* Animation 1: random movement across the screen */
@keyframes randomMove1 {
  0% { transform: translate(0, 0); opacity: 1; }
  25% { transform: translate(50vw, -50vh); opacity: 1; }
  50% { transform: translate(-50vw, 50vh); opacity: 1; }
  75% { transform: translate(100vw, -20vh); opacity: 1; }
  100% { transform: translate(0, 0); opacity: 1; }
}

/* Animation 2: random movement with more variety */
@keyframes randomMove2 {
  0% { transform: translate(0, 0); opacity: 1; }
  30% { transform: translate(30vw, -40vh); opacity: 1; }
  60% { transform: translate(-40vw, 40vh); opacity: 1; }
  90% { transform: translate(80vw, -30vh); opacity: 1; }
  100% { transform: translate(0, 0); opacity: 1; }
}

/* Animation 3: diagonal movement with some curves */
@keyframes randomMove3 {
  0% { transform: translate(0, 0); opacity: 1; }
  40% { transform: translate(-30vw, -40vh); opacity: 1; }
  70% { transform: translate(50vw, 60vh); opacity: 1; }
  100% { transform: translate(0, 0); opacity: 1; }
}

/* Animation 4: random chaotic movement */
@keyframes randomMove4 {
  0% { transform: translate(0, 0); opacity: 1; }
  50% { transform: translate(40vw, 80vh); opacity: 1; }
  80% { transform: translate(-50vw, -70vh); opacity: 1; }
  100% { transform: translate(0, 0); opacity: 1; }
}


